
.home_singlemo{
   
    text-align: center;
    background-color: rgb(236, 238, 238);
}
.main_flex_singlemo{


}
.main2{
    width: 50vw;
    margin-bottom: 2rem;
   width: 50vw;
   min-width: 50vw;
   display: flex;
    overflow: auto;
}
.line{
    border-bottom: 0.2rem solid black;
}
.itemofupcoming_main_singlemo1{
  width: 100%;
  
 justify-content: center;
}
.current{
    width:40vw;
    background-color: aqua;
display: block;
  
}


.slick-slider{
 
}
.current1 {
   
    width: 61vw;
   margin: auto;
}
.upcomingdos{
margin-left: 0vw;
    width: 100%;
    display: flex;
flex-direction: column;
margin-bottom: 1vh;
min-height: 42vh;
    height: 44vh;
    background-color: #dfded9;
max-height: 46vh;
margin-right: 2rem;
padding-left: 2rem;
padding-right: 2rem;
}.left>*{
    font-size: 1.6rem;
}

.left{
margin-left: auto;
}
.right_workstation{

    margin-right: auto;

}
.overall{
    display: flex;
    align-items: center;
    justify-content:start;
}
.right_workstation >*{
    font-size: 1.6rem;
}
.Main_desc_singlemo1{

width: 60vw;
margin: auto; 
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border: 2px solid rgb(78, 76, 76)
}
.Main_desc_singlemo {
    width: 30vw;

    max-width: 40vw;
    text-align: center;
    margin: auto;
    margin-right: 0.8rem;
    height: 100%;
 
  }
@media only screen and (min-width: 2560px) {
    .itemofupcoming_main_singlemo1{
        width: 100%;
       height: 50vh; 
       justify-content: center;
      }
    .upcomingdos{
        margin-left: 0vw;
            width: 100%;
            display: flex;
        flex-direction: column;
        margin-bottom: 1vh;
        min-height:50vh;
            height: 50vh;
            background-color: #dfded9;
        max-height:50vh;
        margin-right: 2rem;
        padding-top: 2rem;
        }
    .line{
        border-bottom: 0.2rem solid black;
    }
    .left>*{
        font-size: 3.2rem;
        padding-left: 3rem;
    }
    .right_workstation>*{
        font-size: 3.5rem;
        padding-right: 2rem;
    }
.Main_desc_singlemo {
  width: 50vw;
  padding-left: 4rem;
padding-right: 6rem;
  max-width: 60vw;
  text-align: center;
  margin: auto;
  margin-right: 1.2rem;
  height: auto;
  
}.runningtext p{
    font-size:4rem;
}
.titleofcurrent{
    text-align: center;
}
.titleofcurrent h1{
  
}

    .home_singlemo{
     
        text-align: center;
        background-color: rgb(236, 238, 238);
    }
    .home_singlemo >div > h1{
        font-size: 5rem;
    }
    
    .current1 {
   
        width: 61vw;
      margin: auto;
    }
    .dec{
        font-size:5rem;
        font-weight: bolder;
    }
}