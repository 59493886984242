@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900;900&display=swap');
body{
   

}

body>*{
    background-color: #edeee2;
}
.home{
  margin: auto;
    padding: 0.5rem;
    background-color: #edeee2;
}
.com{
    font-size: 3.6vh;}

.projector_card{
    margin-top: 1.2vh;
    width:99%;
    height:26vh;
}
.projector_card1{
   

}
.nav_haven{
 
    width:100%;
    height:10vh;

    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
justify-content:left;
}.nav_works{
    display: flex;
    width: 200%;
    justify-content: space-between;
    background-color: blue;
} .bottom > * {text-align: center;
    margin-top: 0.4rem;
    font-size: 1.1vw;
    color: rgb(22, 20, 20);
        
    font-family: 'Roboto', sans-serif;
  }
  .bottom > b {
    color: rgb(0, 0, 0);
  }
  .bottom{
    
      width: 100%;
     height: 7vh;
    position:fixed;
background-color: blanchedalmond;
    bottom:5;
    text-align: center;
  
  }
  
.p-component{

    border: 1px solid rgb(224, 222, 222);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.home h1{
    text-align: center;
    font-size:2vw ;

    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.main_flex{
    display: flex;
    flex-wrap: wrap; gap: 12px;
   
}

.main_flex >*{
    margin: 12px 0 0 12px;
    margin-bottom: 2vw;
}
.main_flex  .Main_desc{
    flex: 2 1 33vw;
}
.Main_descn{
    background-color: white;
    margin-top: 2vh;
  

  margin: auto;
    
    width: 20vw;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Main_desc{
    background-color: white;
    margin-top: 2vh;
  

  
    height: 33vh;
    width: 20vw;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}.partstotal{
    margin-left: 1vw;
    font-size: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.partscount{
    margin-top: 1;
   display: flex;
   justify-content: center;
   align-items: flex-end;
}

.borderbot{

    display: flex;
    justify-content: center;
    width:16vw;
    position: relative;
    left: 42%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-bottom:2px solid rgb(211, 211, 214);
}
.runningtext1{
  position: relative;
  bottom: 0;
    height: 5vh;animation-direction:reverse;
}
.partstotal1{
    margin-left: 0vw;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    text-align: center;
}
.partstotal h1{
    color: rgb(145, 141, 141);
    font-size: 5rem;
}
.partstotal1 h1{
    color: rgb(145, 141, 141);
    font-size: 3.5rem;
}
.statusname Button{
  
}
.statusname{ width: 15vw;
    padding-top: 1.3vh;
    height: 8vh;
    font-weight: bold;
    text-align: center;
    border-radius: 0.9rem;  font-size: 1.5rem;
    
   
    background-color: rgb(171, 231, 163);
    border:none
}
.titleofcurrent{
width:100%;
  
    height:7vh;

    background-color: #2d944e;
}
.spana1{
    font-size: 6.7vh;
    
}
    .spana{
        font-size: 9vh;
    }
.itemofupcoming_main{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 13px;
 justify-content: center;
}
.titlecoming h1{

    font-weight: bolder;
    font-size: 2.3rem;
    text-align: center;
}
.titlecoming{
    margin-top: 1vh;
    margin-bottom: 2vh;
}
.upcomingdos{
margin-left: 0vw;
    width: 100%;
    display: flex;
flex-direction: column;
margin-bottom: 1vh;
    height: 42vh;

}
.up_maindes{
    margin-top: 3vh;
    text-align: center;
}
.up_maindes h1{

font-size:2rem;
font-weight: bolder;
}
.itemofupcoming{
    width: 23.5vw;
    background-color: white;
    height: 34vh;
    max-height: 42vh;
    overflow: auto;
    border: 0.7px solid rgb(201, 194, 194);box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
  .titleofcurrent h1{
    margin-top: 0.6vh;
    font-weight: bolder;
    color: white;
    font-size: 2.5rem;font-family: 'Roboto', sans-serif;
  }

  .up_title{  
    padding-top: 1vh;
    text-align: center;

text-align: center;    
height: 8vh;
    width: 100%;
    background-color: rgb(235, 63, 63);
    align-items: center;
  }

  .up_title h1{
    
      color: white;
      font-size: 2.3rem;
  }
   
body{
   

}

body>*{
    background-color: #f9fafc;
}
.home{
  
    padding: 0.5rem;

}

.projector_card{
    margin-top: 1.2vh;
    width:99%;
    height:26vh;
}
.projector_card1{
   

}
.nav_haven{
 
    width:100%;
    height:10vh;

    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
justify-content:left;
}.nav_works{
    display: flex;
    width: 200%;
    justify-content: space-between;
    background-color: blue;
} .bottom > * {text-align: center;
    margin-top: 0.4rem;
    font-size: 1.1vw;
    color: rgb(22, 20, 20);
        
    font-family: 'Roboto', sans-serif;
  }
  .bottom > b {
    color: rgb(0, 0, 0);
  }
  .bottom{
    
      width: 100%;
     height: 7vh;
    position:fixed;
background-color: blanchedalmond;
    bottom:5;
    text-align: center;
  
  }
  
.p-component{

    border: 1px solid rgb(224, 222, 222);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.home h1{
    text-align: center;
    font-size:2.5vw ;

    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.Main_desc h1{
    font-size: 3.5vh;
}

.partstotal{
    margin-left: 1vw;
    font-size: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}
.partscount{
    margin-top: 1;
   display: flex;
   justify-content: center;
   align-items: flex-end;
}

.borderbot{

    display: flex;
    justify-content: center;
    width:16vw;
    position: relative;
    left: 42%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-bottom:2px solid rgb(211, 211, 214);
}
.runningtext{
  position: relative;
  bottom: 0;

    height: 10vh;
    width: auto;
    color: white;
    background-color: rgb(64, 64, 146);
    padding:0.5vh;
    overflow: hidden;
    font-size: 2.5rem;
}

.partstotal1{
    margin-left: 0vw;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    text-align: center;
}
.partstotal h1{
    color: rgb(145, 141, 141);
    font-size: 7rem;
}
.partstotal1 h1{
    color: rgb(145, 141, 141);
    font-size: 5rem;
    margin-top: 1rem;
}
.statusname Button{
  
}
.statusname{ width: 15vw;
    padding-top: 1.1vh;
    height: 8vh;
    font-weight: bold;
    text-align: center;
    border-radius: 0.9rem;  font-size: 1.5rem;
    
    background-color: rgb(171, 231, 163);
    border:none
}
.titleofcurrent{
width:100%;
margin-top: -0.5vw;
    height:7vh;
    font-size: 5vh;

    background-color: #2d944e;
}
.itemofupcoming_main{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 13px;
 justify-content: center;
}
.titlecoming h1{

    font-weight: bolder;
    font-size: 2.3rem;
    text-align: center;
}
.titlecoming{
    margin-top: 1vh;
    margin-bottom: 2vh;
}
.upcomingdos{
margin-left: 0vw;
    width: 100%;
    display: flex;
flex-direction: column;
margin-bottom: 1vh;
    height: 42vh;

}
.up_maindes{
    margin-top: 3vh;
    text-align: center;
}
.up_maindes h1{

font-size:2rem;
font-weight: bolder;
}
.itemofupcoming{
    width: 23.5vw;
    background-color: white;
    height: 34vh;
    max-height: 42vh;
    overflow: auto;
    border: 0.7px solid rgb(201, 194, 194);box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
  .titleofcurrent h1{
    margin-top: 0.6vh;
    font-weight: bolder;
    font-size: 5vh;
    color: white;
    font-size: 3.5rem;font-family: 'Roboto', sans-serif;
  }

  .up_title{  
    padding-top: 1vh;
    text-align: center;

text-align: center;    
height: 8vh;
    width: 100%;
    background-color: rgb(235, 63, 63);
    align-items: center;
  }

  .up_title h1{
    
      color: white;
      font-size: 2.3rem;
  }

  @media only screen and (min-width: 2560px) {
    body{
        background-color: #122752;

    }
    
    body>*{
        background-color: #122752;
    }
    .home{
        background-color: #dfded9;
        padding: 0.5rem;
    
    }
    
    .projector_card{
        margin-top: 1.2vh;
        width:99%;
        height:26vh;
    }
    .projector_card1{
       
    
    }
    .nav_haven{
     
        width:100%;
        height:10vh;
    
        background-color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
    justify-content:left;
    }.nav_works{
        display: flex;
        width: 200%;
        justify-content: space-between;
        background-color: blue;
    } .bottom > * {text-align: center;
        margin-top: 0.4rem;
        font-size: 1.1vw;
        color: rgb(22, 20, 20);
            
        font-family: 'Roboto', sans-serif;
      }
      .bottom > b {
        color: rgb(0, 0, 0);
      }
      .bottom{
        
          width: 100%;
         height: 7vh;
        position:fixed;
    background-color: blanchedalmond;
        bottom:5;
        text-align: center;
      
      }
      
    .p-component{
    
        border: 1px solid rgb(224, 222, 222);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .home h1{
        text-align: center;
        font-size:2vw ;
    
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
        
  .main_flex{
    display: flex;
    flex-wrap: wrap; gap: 12px;
   
}

.main_flex >*{
    
    margin-bottom: 0.51vw;

}
.main_flex  .Main_desc{
    flex: 4 1 auto;
}
    .Main_desc{
        background-color: white;
        margin-top: 1vh;
    
      border:2px solid black;
        height: 40vh;
     width: 50rem;
        max-width:250rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }.partstotal{
        margin-left: 1vw;
        font-size: 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
    
    }
    .partscount{
        margin-top: 1;
       display: flex;
       justify-content: center;
       align-items: flex-end;
    }
    
    .borderbot{
    
        display: flex;
        justify-content: center;
        width:16vw;
        position: relative;
        left: 42%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-bottom:2px solid rgb(211, 211, 214);
    }
    .runningtext{
      position: relative;
      bottom: 0;
        height: 8vh;
        width: auto;
    }
    .partstotal1{
        margin-left: 0vw;
        font-size: 3.5rem;
        display: flex;
        justify-content: center;
        align-items:flex-end;
        text-align: center;
    }
    .partstotal h1{
        color: rgb(145, 141, 141);
        font-size: 5rem;
    }
    .partstotal1 h1{
        color: rgb(145, 141, 141);
        font-size: 3.5rem;
    }
    .statusname Button{
      
    }
    .statusname{ width: 15vw;
        padding-top: 1.3vh;
        height: 8vh;
        font-weight: bold;
        text-align: center;
        border-radius: 0.9rem;  font-size: 1.5rem;
        
       
        background-color: rgb(171, 231, 163);
        border:none
    }
    .titleofcurrent{
    width:100%;
      
        height:7vh;
    
        background-color: #2d944e;
    }
    .itemofupcoming_main{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 13px;
     justify-content: center;
    }
    .titlecoming h1{
    
        font-weight: bolder;
        font-size: 2.3rem;
        text-align: center;
    }
    .titlecoming{
        margin-top: 1vh;
        margin-bottom: 2vh;
    }
    .upcomingdos{
    margin-left: 0vw;
        width: 100%;
        display: flex;
    flex-direction: column;
    margin-bottom: 1vh;
        height: 42vh;
    
    }
    .up_maindes{
        margin-top: 3vh;
        text-align: center;
    }
    .up_maindes h1{
    
    font-size:2rem;
    font-weight: bolder;
    }
    .itemofupcoming{
        width: 23.5vw;
        background-color: white;
        height: 34vh;
        max-height: 42vh;
        overflow: auto;
        border: 0.7px solid rgb(201, 194, 194);box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
      .titleofcurrent h1{
        margin-top: 0.6vh;
        font-weight: bolder;
        color: white;
        font-size: 2.5rem;font-family: 'Roboto', sans-serif;
      }
    
      .up_title{  
        padding-top: 1vh;
        text-align: center;
    
    text-align: center;    
    height: 8vh;
        width: 100%;
        background-color: rgb(235, 63, 63);
        align-items: center;
      }
    
      .up_title h1{
        
          color: white;
          font-size: 2.3rem;
      }
       
    body{
       
    
    }
    
    body>*{
        background-color: #f9fafc;
    }
    .home{
      
        padding: 0.5rem;
    
    }
    
    .projector_card{
        margin-top: 1.2vh;
        width:99%;
        height:26vh;
    }
    .projector_card1{
       
    
    }
    .nav_haven{
     
        width:100%;
        height:10vh;
    
        background-color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
    justify-content:left;
    }.nav_works{
        display: flex;
        width: 200%;
        justify-content: space-between;
        background-color: blue;
    } .bottom > * {text-align: center;
        margin-top: 0.4rem;
        font-size: 1.1vw;
        color: rgb(22, 20, 20);
            
        font-family: 'Roboto', sans-serif;
      }
      .bottom > b {
        color: rgb(0, 0, 0);
      }
      .bottom{
        
          width: 100%;
         height: 7vh;
        position:fixed;
    background-color: blanchedalmond;
        bottom:5;
        text-align: center;
      
      }
      
    .p-component{
    
        border: 1px solid rgb(224, 222, 222);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .home h1{
        text-align: center;
        font-size:2.5vw ;
    
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .Main_desc h1{
        font-size: 3.5vh;
    }
    
 .partstotal{
        margin-left: 1vw;
        font-size: 4rem;
        margin-top: 1vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
    }
    .partscount{
        margin-top: 1;
       display: flex;
       justify-content: center;
       align-items: flex-end;
    }
    
    .borderbot{
    
        display: flex;
        justify-content: center;
        width:16vw;
        position: relative;
        left: 42%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-bottom:2px solid rgb(149, 158, 158);
    }
    .runningtext{
      position: relative;
      bottom: 0;
   
        height: 12vh;
    }
    .partstotal1{
        margin-left: 0vw;
        font-size: 3.9vh;
        display: flex;
        justify-content: center;
        align-items:flex-end;
        text-align: center;
        padding-top: 1.8vh;
    }
.spana1{
    font-size: 5vh;
    
}
    .spana{
        font-size: 8vh;
    }
    .partstotal h1{
        color: rgb(145, 141, 141);
        font-size: 8vh;
    }
    .partstotal1 h1{
        color: rgb(145, 141, 141);
        font-size: 5vh;
    }
    .statusname Button{
      
    }
    .statusname{ width:8vw;
        padding-top: 1.3vh;
        height: 8vh;
        font-weight: bold;
        text-align: center;
        border-radius: 0.9rem;  font-size: 1.5rem;
        font-size: 1vw;
       
        background-color: rgb(171, 231, 163);
        border:none
    }
    .com{
        font-size: 1vw;}
    .titleofcurrent{
    width:100%;
      margin-top: -0.6rem;
        height:8.3vh;
        font-size: 5vh;
    
        background-color: #2d944e;
    }
    .itemofupcoming_main{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 13px;
     justify-content: center;
    }
    .titlecoming h1{
    
        font-weight: bolder;
        font-size: 3.7vh;
        text-align: center;
    }
    .titlecoming{
        margin-top: 1vh;
        margin-bottom: 2vh;
    }
    .upcomingdos{
    margin-left: 0vw;
        width: 100%;
        display: flex;
    flex-direction: column;
    margin-bottom: 1vh;
        height: 42vh;
    
    }
    .up_maindes{
        padding-right: 0.5vh;
        padding-left: 0.5vh;
        margin-top: 3vh;
        text-align: center;
    }
    .up_maindes h1{
    
    font-size:2.5vh;

    font-weight: bolder;
    }
    .itemofupcoming{
        width: 23.5vw;
        background-color: white;
        height: 36vh;
        max-height: 42vh;
        overflow: auto;
        border: 0.7px solid rgb(201, 194, 194);box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
      .titleofcurrent h1{
        margin-top: 0.6vh;
        font-weight: bolder;
        font-size: 5vh !important;
        color: white;
        font-size: 2.5rem;font-family: 'Roboto', sans-serif;
      }
    
      .up_title{  
        padding-top: 1.9vh;
        text-align: center;
    
    text-align: center;    
    height: 8vh;
        width: 100%;
        background-color: rgb(235, 63, 63);
        align-items: center;
      }
    
      .up_title h1{
        
          color: white;
          font-size: 3.3vh;
      }
    
  }